import { Box } from "@mui/material";
import Header from "../components/Header";
import BarChart from "../components/BarChart";
import React from 'react';
import Layout from '../pages/global/Layout';

const Bar = () => {
  
  return (
    <Layout>
      <div>
    <Box m="20px">
      <Header title="Total Milk Sales" subtitle="Total Goat Milk" />
      <Box height="75vh">
        <BarChart />
      </Box>
    </Box>
    </div>
    </Layout>
  );
};

export default Bar;
