import React, { useEffect, useState, useRef } from 'react';
import { API, Auth, Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { listBookings, listUsers, listCalendars } from '../../graphql/queries';
import { deleteBooking, updateBooking } from '../../graphql/mutations';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, TablePagination, Checkbox, Box, FormControl, InputLabel, Select, MenuItem,
    useTheme
} from '@mui/material';
import { tokens } from "../theme";
import Header from "../components/Header";
import Layout from './global/Layout';
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import dayjs from 'dayjs';
import AWS from 'aws-sdk';
import { CognitoIdentityCredentials } from 'aws-sdk';

Amplify.configure(awsconfig);

// Set AWS credentials
AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION,
});

async function configureAWS() {
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
}

await configureAWS();

const ses = new SESClient({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: AWS.config.credentials, // Use configured credentials
});

const notifyUser = async (email, subject, message) => {
    if (!email || !message) {
        console.error("Invalid input: email or message is missing");
        throw new Error("Invalid input: email or message is missing");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        console.error("Invalid email format:", email);
        throw new Error("Invalid email format");
    }

    const params = {
        Destination: { ToAddresses: [email] },
        Message: {
            Body: { Text: { Charset: "UTF-8", Data: message } },
            Subject: { Charset: 'UTF-8', Data: subject }
        },
        Source: 'noreply@theshepherdfarm.com.my'
    };

    try {
        console.log(`Sending email to ${email} with subject "${subject}" and message "${message}"`);
        const data = await ses.send(new SendEmailCommand(params));
        console.log("Email sent successfully:", data);
        return "Email sent successfully";
    } catch (error) {
        console.error("Error sending email:", error);
        console.error("Error details:", error.message, error.stack);
        throw new Error("Failed to send email");
    }
};

const lambda = new AWS.Lambda();

const sendBookingConfirmation = async (email, bookingDetails, action, fullName) => {
    const params = {
        FunctionName: 'goatSendEmailNotification-dev',
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({
            arguments: {
                input: {
                    email,
                    bookingDetails,
                    action,
                    fullName
                }
            }
        })
    };

    try {
        console.log(`Invoking Lambda function for email: ${email}`);
        const data = await lambda.invoke(params).promise();
        console.log("Lambda function invoked successfully:", data);
        return "Booking confirmation email sent successfully";
    } catch (error) {
        console.error("Error invoking Lambda function:", error);
        throw new Error("Failed to invoke Lambda function");
    }
};

const Appointments = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(-1);
    const [selected, setSelected] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedDay, setSelectedDay] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedtimeslot, setSelectedTimeslot] = useState('');
    const printRef = useRef(null);

    useEffect(() => {
        const fetchBookingsAndCalendars = async () => {
            try {
                console.log('Fetching bookings...');
                const bookingData = await API.graphql({ query: listBookings });
                console.log('Booking data:', bookingData);

                if (bookingData.data && bookingData.data.listBookings && bookingData.data.listBookings.items) {
                    const bookings = bookingData.data.listBookings.items;

                    const allUsers = await fetchAllUsers();
                    console.log('User data:', allUsers);

                    const calendarData = await API.graphql({ query: listCalendars });
                    console.log('Calendar data:', calendarData);

                    if (allUsers.length > 0 && calendarData.data && calendarData.data.listCalendars && calendarData.data.listCalendars.items) {
                        const usersMap = allUsers.reduce((acc, user) => {
                            acc[user.id] = {
                                name: `${user.firstName} ${user.lastName}`,
                                email: user.email,
                            };
                            return acc;
                        }, {});
                        console.log('Users Map:', usersMap);

                        const calendars = calendarData.data.listCalendars.items;

                        const bookingsList = bookings.filter(booking => {
                            const bookingTime = dayjs(booking.time, 'HH:mm');
                            const bookingEndTime = bookingTime.add(1.5, 'hour');

                            const hasMatchingEvent = calendars.some(calendar => {
                                const eventTime = dayjs(calendar.time, 'HH:mm');
                                const eventEndTime = eventTime.add(1.5, 'hour');
                                return calendar.date === booking.date && (
                                    (bookingTime.isBetween(eventTime, eventEndTime, null, '[)') ||
                                    bookingEndTime.isBetween(eventTime, eventEndTime, null, '(]'))
                                );
                            });

                            return !hasMatchingEvent;
                        }).map(booking => ({
                            id: booking.id,
                            date: booking.date,
                            time: booking.time,
                            organisationName: booking.organisationName,
                            phoneNumber: booking.phoneNumber,
                            email: usersMap[booking.owner]?.email || 'Unknown Email',
                            tpAdult: booking.tpAdult,
                            tpChildBelowThree: booking.tpChildBelowThree,
                            tpChildBelowTwelve: booking.tpChildBelowTwelve,
                            tpSeniorCitizen: booking.tpSeniorCitizen,
                            pbGoatMilkOrigin: booking.pbGoatMilkOrigin,
                            pbGoatMilkChoco: booking.pbGoatMilkChoco,
                            pbGoatMilkCaramel: booking.pbGoatMilkCaramel,
                            total: booking.total,
                            owner: booking.owner,
                            fullName: usersMap[booking.owner]?.name || 'Unknown User',
                            createdAt: booking.createdAt,
                            status: booking.status || 'PENDING',
                        }));

                        setRows(bookingsList);
                    } else {
                        console.error('No users or calendar events found.');
                    }
                } else {
                    console.error('No bookings found.');
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching bookings and calendar events:', error);
                setLoading(false);
            }
        };

        // Function to fetch all users with pagination
        const fetchAllUsers = async () => {
            let users = [];
            let nextToken = null;

            do {
                const userData = await API.graphql({
                    query: listUsers,
                    variables: { nextToken }
                });

                users = users.concat(userData.data.listUsers.items);
                nextToken = userData.data.listUsers.nextToken;
            } while (nextToken);

            return users;
        };

        fetchBookingsAndCalendars();
    }, []);

    const handlePrint = () => {
        // Calculate totals for each relevant column using filteredRows
        const totalAdults = filteredRows.reduce((sum, row) => sum + row.tpAdult, 0);
        const totalChildrenBelow3 = filteredRows.reduce((sum, row) => sum + row.tpChildBelowThree, 0);
        const totalChildrenBelow12 = filteredRows.reduce((sum, row) => sum + row.tpChildBelowTwelve, 0);
        const totalSeniorCitizens = filteredRows.reduce((sum, row) => sum + row.tpSeniorCitizen, 0);
        const totalGoatMilkOriginal = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkOrigin, 0);
        const totalGoatMilkChoco = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkChoco, 0);
        const totalGoatMilkCaramel = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkCaramel, 0);
        const totalPrice = filteredRows.reduce((sum, row) => sum + row.total, 0);
    
        const printContents = `
            <html>
            <head>
                <title>Print Appointment List</title>
                <style>
                    @media print {
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            margin-bottom: 10px;
                        }
                        th, td {
                            border: 1px solid black;
                            padding: 8px;
                            text-align: left;
                        }
                        th {
                            background-color: #f2f2f2;
                        }
                        .totals-row {
                            font-weight: bold;
                        }
                        .totals-row td {
                            text-align: left;
                        }
                    }
                </style>
            </head>
            <body>
                <h2>Appointment List</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Full Name</th>
                            <th>Time</th>
                            <th>Organisation Name</th>
                            <th>Phone Number</th>
                            <th>Email</th>
                            <th>Adult</th>
                            <th>Child Below 3</th>
                            <th>Child Below 12</th>
                            <th>Senior Citizen</th>
                            <th>Goat Milk (Original)</th>
                            <th>Goat Milk (Choco)</th>
                            <th>Goat Milk (Caramel)</th>
                            <th>Total Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${filteredRows.map(row => `
                            <tr>
                                <td>${dayjs(row.date).format('YYYY-MM-DD')}</td>
                                <td>${row.fullName}</td>
                                <td>${row.time}</td>
                                <td>${row.organisationName}</td>
                                <td>${row.phoneNumber}</td>
                                <td>${row.email}</td>
                                <td>${row.tpAdult}</td>
                                <td>${row.tpChildBelowThree}</td>
                                <td>${row.tpChildBelowTwelve}</td>
                                <td>${row.tpSeniorCitizen}</td>
                                <td>${row.pbGoatMilkOrigin}</td>
                                <td>${row.pbGoatMilkChoco}</td>
                                <td>${row.pbGoatMilkCaramel}</td>
                                <td>RM ${row.total}</td>
                            </tr>
                        `).join('')}
                        <tr class="totals-row">
                            <td colspan="6">Totals:</td>
                            <td>${totalAdults}</td>
                            <td>${totalChildrenBelow3}</td>
                            <td>${totalChildrenBelow12}</td>
                            <td>${totalSeniorCitizens}</td>
                            <td>${totalGoatMilkOriginal}</td>
                            <td>${totalGoatMilkChoco}</td>
                            <td>${totalGoatMilkCaramel}</td>
                            <td>RM ${totalPrice}</td>
                        </tr>
                    </tbody>
                </table>
                <script>window.print();</script>
            </body>
            </html>
        `;
    
        const popupWin = window.open('', '_blank', 'width=600,height=600');
        popupWin.document.open();
        popupWin.document.write(printContents);
        popupWin.document.close();
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        // If a special value for "all rows" is chosen, set rowsPerPage to a large number or -1
        const newRowsPerPage = event.target.value === "all" ? -1 : parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    }; 

    const handleDelete = async () => {
        const confirmed = window.confirm("Are you sure you want to delete the selected bookings?");
        if (!confirmed) return;

        try {
            const user = await Auth.currentAuthenticatedUser();
            console.log("Authenticated user:", user);

            const deletionPromises = selected.map(async (id) => {
                console.log(`Attempting to delete booking with ID: ${id}`);
                await API.graphql({
                    query: deleteBooking,
                    variables: { input: { id } },
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                });
                console.log(`Successfully deleted booking with ID: ${id}`);
            });

            await Promise.all(deletionPromises);
            setRows(rows.filter(row => !selected.includes(row.id)));
            setSelected([]);
        } catch (error) {
            console.error('Error deleting booking:', error);
        }
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleDayChange = (event) => {
        setSelectedDay(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setSelectedStatus(event.target.value);
    };

    const handleTimeslotChange = (event) => {
        setSelectedTimeslot(event.target.value);
    };

    const filteredRows = rows.filter(row => {
        const bookingDate = dayjs(row.date);
        const isMonthMatch = selectedMonth ? bookingDate.month() === parseInt(selectedMonth, 10) - 1 : true;
        const isYearMatch = selectedYear ? bookingDate.year() === parseInt(selectedYear, 10) : true;
        const isDayMatch = selectedDay ? dayjs(row.date).date() === parseInt(selectedDay, 10) : true;
        const isStatusMatch = selectedStatus ? row.status === selectedStatus : true;
        const timeslotMatch = selectedtimeslot ? row.time === selectedtimeslot : true;
        return isMonthMatch && isYearMatch && isDayMatch && isStatusMatch && timeslotMatch;
    });

    const handleSendEmail = async (email, bookingDetails, action) => {
        const fullName = bookingDetails.fullName;
        try {
            await sendBookingConfirmation(email, bookingDetails, action, fullName);

            const newStatus = action === 'confirm' ? 'CONFIRMED' : 'REJECTED';
            await API.graphql({
                query: updateBooking,
                variables: { input: { id: bookingDetails.id, status: newStatus } }
            });

            setRows(prevRows =>
                prevRows.map(row => (row.id === bookingDetails.id ? { ...row, status: newStatus } : row))
            );

            if (email) {
                const subject = `Booking Status Updated to ${newStatus}`;
                const message = `Hello ${fullName},\n\nYour booking status has been updated to ${newStatus}.\n\nThank you.`;
                await notifyUser(email, subject, message);
            }
        } catch (error) {
            console.error('Error updating booking status:', error);
        }
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleStatusChange = async (event, bookingDetails, action) => {
        const newStatus = action === 'confirm' ? 'CONFIRMED' : 'REJECTED';
        try {
            await API.graphql({
                query: updateBooking,
                variables: { input: { id: bookingDetails.id, status: newStatus } }
            });

            setRows(prevRows =>
                prevRows.map(row => (row.id === bookingDetails.id ? { ...row, status: newStatus } : row))
            );

            if (bookingDetails.email) {
                const subject = `Booking Status Updated to ${newStatus}`;
                const message = `Hello ${bookingDetails.fullName},\n\nYour booking status has been updated to ${newStatus}.\n\nThank you.`;
                await notifyUser(bookingDetails.email, subject, message);
            }
        } catch (error) {
            console.error('Error updating booking status:', error);
        }
    };

    // Calculate totals for each relevant column using filteredRows
    const totalAdults = filteredRows.reduce((sum, row) => sum + row.tpAdult, 0);
    const totalChildrenBelow3 = filteredRows.reduce((sum, row) => sum + row.tpChildBelowThree, 0);
    const totalChildrenBelow12 = filteredRows.reduce((sum, row) => sum + row.tpChildBelowTwelve, 0);
    const totalSeniorCitizens = filteredRows.reduce((sum, row) => sum + row.tpSeniorCitizen, 0);
    const totalGoatMilkOriginal = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkOrigin, 0);
    const totalGoatMilkChoco = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkChoco, 0);
    const totalGoatMilkCaramel = filteredRows.reduce((sum, row) => sum + row.pbGoatMilkCaramel, 0);
    const totalPrice = filteredRows.reduce((sum, row) => sum + row.total, 0);

    return (
        <Layout>
            <Header title="Appointments" />
            <Box sx={{ p: 2 }}>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-day-label">Day</InputLabel>
                    <Select
                        labelId="select-day-label"
                        id="select-day"
                        value={selectedDay}
                        label="Day"
                        onChange={handleDayChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>{index + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-month-label">Month</InputLabel>
                    <Select
                        labelId="select-month-label"
                        id="select-month"
                        value={selectedMonth}
                        label="Month"
                        onChange={handleMonthChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {[...Array(12).keys()].map(month => (
                            <MenuItem key={month + 1} value={month + 1}>{dayjs().month(month).format('MMMM')}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-year-label">Year</InputLabel>
                    <Select
                        labelId="select-year-label"
                        id="select-year"
                        value={selectedYear}
                        label="Year"
                        onChange={handleYearChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {[...Array(5).keys()].map(year => (
                            <MenuItem key={year} value={dayjs().year() - year}>{dayjs().year() - year}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-timeslot-label">Time Slot</InputLabel>
                    <Select
                        labelId="select-timeslot-label"
                        id="select-timeslot"
                        value={selectedtimeslot}
                        label="Time Slot"
                        onChange={handleTimeslotChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="09:00 - 10:30">09:00 - 10:30</MenuItem>
                        <MenuItem value="10:30 - 12:00">10:30 - 12:00</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="select-status-label">Status</InputLabel>
                    <Select
                        labelId="select-status-label"
                        id="select-status"
                        value={selectedStatus}
                        label="Status"
                        onChange={handleStatusFilterChange}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="CONFIRMED">Confirmed</MenuItem>
                        <MenuItem value="REJECTED">Rejected</MenuItem>
                    </Select>
                </FormControl>

            </Box>

            <Paper sx={{ width: '100%', marginBottom: 2 }}>
                <TableContainer>
                    <Table sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < rows.length}
                                        checked={rows.length > 0 && selected.length === rows.length}
                                        onChange={handleSelectAllClick}
                                        inputProps={{ 'aria-label': 'select all appointments' }}
                                    />
                                </TableCell>
                                <TableCell align="left">Date</TableCell>
                                <TableCell align="left">Full Name</TableCell>
                                <TableCell align="left">Time</TableCell>
                                <TableCell align="left">Organisation Name</TableCell>
                                <TableCell align="left">Phone Number</TableCell>
                                <TableCell align="left">Email</TableCell>
                                <TableCell align="left">Total Persons (Adult)</TableCell>
                                <TableCell align="left">Total Persons (Child Below 3)</TableCell>
                                <TableCell align="left">Total Persons (Child Below 12)</TableCell>
                                <TableCell align="left">Total Persons (SeniorCitizen)</TableCell>
                                <TableCell align="left">Goat Milk (Original)</TableCell>
                                <TableCell align="left">Goat Milk (Choco)</TableCell>
                                <TableCell align="left">Goat Milk (Caramel)</TableCell>
                                <TableCell align="left">Total Price</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={16} align="center">Loading...</TableCell>
                                </TableRow>
                            ) : (
                                (rowsPerPage > 0
                                    ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : filteredRows
                                ).map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            key={row.id}
                                            hover
                                            onClick={(event) => handleClick(event, row.id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">{dayjs(row.date).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell align="left">{row.fullName}</TableCell>
                                            <TableCell align="left">{row.time}</TableCell>
                                            <TableCell align="left">{row.organisationName}</TableCell>
                                            <TableCell align="left">{row.phoneNumber}</TableCell>
                                            <TableCell align="left">{row.email}</TableCell>
                                            <TableCell align="left">{row.tpAdult}</TableCell>
                                            <TableCell align="left">{row.tpChildBelowThree}</TableCell>
                                            <TableCell align="left">{row.tpChildBelowTwelve}</TableCell>
                                            <TableCell align="left">{row.tpSeniorCitizen}</TableCell>
                                            <TableCell align="left">{row.pbGoatMilkOrigin}</TableCell>
                                            <TableCell align="left">{row.pbGoatMilkChoco}</TableCell>
                                            <TableCell align="left">{row.pbGoatMilkCaramel}</TableCell>
                                            <TableCell align="left">{row.total}</TableCell>
                                            <TableCell align="left">{row.status}</TableCell>
                                            <TableCell align="left">
                                                {row.status === 'PENDING' && (
                                                    <>
                                                        <Button
                                                            onClick={() => handleSendEmail(row.email, row, 'confirm')}
                                                            variant="contained"
                                                            color="primary"
                                                            sx={{ mr: 1 }}
                                                        >
                                                            Approve
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleSendEmail(row.email, row, 'reject')}
                                                            variant="contained"
                                                            color="secondary"
                                                        >
                                                            Reject
                                                        </Button>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            )}
                            {/* Add the totals row */}
                            <TableRow>
                                <TableCell colSpan={7} align="right"><strong>Totals:</strong></TableCell>
                                <TableCell align="left"><strong>{totalAdults}</strong></TableCell>
                                <TableCell align="left"><strong>{totalChildrenBelow3}</strong></TableCell>
                                <TableCell align="left"><strong>{totalChildrenBelow12}</strong></TableCell>
                                <TableCell align="left"><strong>{totalSeniorCitizens}</strong></TableCell>
                                <TableCell align="left"><strong>{totalGoatMilkOriginal}</strong></TableCell>
                                <TableCell align="left"><strong>{totalGoatMilkChoco}</strong></TableCell>
                                <TableCell align="left"><strong>{totalGoatMilkCaramel}</strong></TableCell>
                                <TableCell align="left"><strong>RM {totalPrice}</strong></TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, { label: '1000', value: 1000 }]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                <Button
                    onClick={handlePrint}
                    variant="contained"
                    color="primary"
                >
                    Print
                </Button>
                <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="secondary"
                    sx={{ ml: 1 }}
                >
                    Delete Selected
                </Button>
            </Box>

            <div style={{ display: 'none' }}>
                <div ref={printRef}>
                    <Paper sx={{ width: '100%', marginBottom: 2 }}>
                        <TableContainer>
                            <Table sx={{ minWidth: 750 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Full Name</TableCell>
                                        <TableCell>Time</TableCell>
                                        <TableCell>Organisation Name</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Total Persons (Adult)</TableCell>
                                        <TableCell>Total Persons (Child Below 3)</TableCell>
                                        <TableCell>Total Persons (Child Below 12)</TableCell>
                                        <TableCell>Total Persons (Senior Citizen)</TableCell>
                                        <TableCell>Goat Milk (Original)</TableCell>
                                        <TableCell>Goat Milk (Choco)</TableCell>
                                        <TableCell>Goat Milk (Caramel)</TableCell>
                                        <TableCell>Total Price</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow key={row.id}>
                                            <TableCell>{dayjs(row.date).format('YYYY-MM-DD')}</TableCell>
                                            <TableCell>{row.fullName}</TableCell>
                                            <TableCell>{row.time}</TableCell>
                                            <TableCell>{row.organisationName}</TableCell>
                                            <TableCell>{row.phoneNumber}</TableCell>
                                            <TableCell>{row.email}</TableCell>
                                            <TableCell>{row.tpAdult}</TableCell>
                                            <TableCell>{row.tpChildBelowThree}</TableCell>
                                            <TableCell>{row.tpChildBelowTwelve}</TableCell>
                                            <TableCell>{row.tpSeniorCitizen}</TableCell>
                                            <TableCell>{row.pbGoatMilkOrigin}</TableCell>
                                            <TableCell>{row.pbGoatMilkChoco}</TableCell>
                                            <TableCell>{row.pbGoatMilkCaramel}</TableCell>
                                            <TableCell>RM{row.total}</TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={7} align="right"><strong>Totals:</strong></TableCell>
                                        <TableCell align="left"><strong>{totalAdults}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalChildrenBelow3}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalChildrenBelow12}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalSeniorCitizens}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalGoatMilkOriginal}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalGoatMilkChoco}</strong></TableCell>
                                        <TableCell align="left"><strong>{totalGoatMilkCaramel}</strong></TableCell>
                                        <TableCell align="left"><strong>RM {totalPrice}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </div>
            </div>
        </Layout>
    );
};

export default Appointments;
