import React, { useEffect, useState } from 'react';
import { API, Amplify } from 'aws-amplify';
import awsconfig from '../../aws-exports'; // Adjust the path as needed
import { listUsers } from '../../graphql/queries'; // Ensure the path is correct

import { Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useTheme } from '@mui/material';
import { tokens } from '../theme';
import Header from '../components/Header';
import Layout from './global/Layout';

Amplify.configure(awsconfig);

const FetchBookings = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        console.log('Fetching users...');
        const userData = await API.graphql({ query: listUsers });
        console.log('User data:', userData);

        if (userData.data && userData.data.listUsers && userData.data.listUsers.items) {
          const usersList = userData.data.listUsers.items.map((user, index) => ({
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            userName: user.userName,
            phoneNumber: user.phoneNumber,
            organisationName: user.organisationName,
            createdAt: new Date(user.createdAt).toLocaleString(),
            updatedAt: new Date(user.updatedAt).toLocaleString(),
            owner: user.owner,
          }));

          setRows(usersList);
        } else {
          console.error('No users found.');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching users:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const columns = [
    { field: 'firstName', headerName: 'First Name', flex: 1 },
    { field: 'lastName', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'userName', headerName: 'User Name', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
    { field: 'organisationName', headerName: 'Organisation Name', flex: 1 },
    { field: 'createdAt', headerName: 'Created At', flex: 1 },
    { field: 'updatedAt', headerName: 'Updated At', flex: 1 },
    // { field: 'owner', headerName: 'Owner', flex: 1 },
  ];

  return (
    <Layout>
      <div>
        <Box m="20px">
          <Header title="Customer List" subtitle="List of Customer" />
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              '& .MuiDataGrid-root': {
                border: 'none',
              },
              '& .MuiDataGrid-cell': {
                borderBottom: 'none',
                color: colors.grey[100],
              },
              '& .name-column--cell': {
                color: colors.greenAccent[300],
              },
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: colors.blueAccent[700],
                borderBottom: 'none',
              },
              '& .MuiDataGrid-virtualScroller': {
                backgroundColor: colors.primary[400],
              },
              '& .MuiDataGrid-footerContainer': {
                borderTop: 'none',
                backgroundColor: colors.blueAccent[700],
              },
              '& .MuiCheckbox-root': {
                color: `${colors.greenAccent[200]} !important`,
              },
              '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                color: `${colors.grey[100]} !important`,
              },
              '& .MuiDataGrid-row:hover': {
                backgroundColor: colors.primary[600],
                color: colors.grey[100], // Ensure text color is visible on hover
              },
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              loading={loading}
            />
          </Box>
        </Box>
      </div>
    </Layout>
  );
};

export default FetchBookings;
